import { orderBy, groupBy, uniqBy, uniq, sortBy } from 'lodash-es'

export const getEditionsNavigationGroups = (data: array) => {
  const years = computed(() => {
    const entries = data
      ?.map((entry) => { return getYears(entry?.dateStart, entry?.dateEnd).map((i, index) => entryObject(entry, index)) })
      ?.flat()
      ?.filter(entry => entry.year)
    if (!entries) { return [] }
    return orderBy(uniqBy(entries, 'year'), ['year'], ['desc'])
  })

  const yearsLength = computed(() => {
    return years?.value?.length
  })

  const countries = computed(() => {
    const countries = data
      ?.map(entry => entry?.country)
      ?.flat()
      ?.filter(Boolean)
      ?.sort()
    if (!countries) { return [] }
    const items = uniq(countries)?.map((country) => {
      return {
        country: country,
        countryName: i18nCountryNameForCode(country),
        editions: data?.filter(i => i?.country === country)?.filter(Boolean)?.map(entry => entryObject(entry))?.sort((a, b) => a?.editionIndex - b?.editionIndex)
      }
    })
    return orderBy(items, [(i) => i?.countryName?.toLowerCase()], ['asc'])
  })

  const editions = computed(() => {
    const entries = data
      ?.map(entry => entryObject(entry))
      ?.flat()
    if (!entries) { return [] }
    return orderBy(entries, ['editionIndex'], ['desc'])
  })

  const countriesLength = computed(() => {
    return Object.keys(countries?.value)?.length
  })

  const entryObject = (entry: object, yearIndex = 0) => {
    return {
      country: entry?.country,
      city: entry?.city,
      countryName: i18nCountryNameForCode(entry?.country),
      editionIndex: entry?.editionIndex,
      year: getYears(entry?.dateStart, entry?.dateEnd)[yearIndex],
      slug: entry?.slug,
      title: entry?.title,
    }
  }

  const getYears = (dateStart: string, dateEnd: string) => {
    const dStart = dateStart ? new Date(dateStart) : null
    const dEnd = dateEnd ? new Date(dateEnd) : null
    return [dStart?.getFullYear(), dEnd?.getFullYear()].filter(Boolean).sort((a, b) => b - a)
  }

  const entries = ref({
    years,
    countries,
    editions,
    yearsLength,
    countriesLength
  })

  return entries.value
}
